.hero-img{
    width:100%;
    background:rgba(0,0,0,0.5);
    height:60vh;
    position:relative;
}

.hero-img::before{
    content:"";
    background:url("../Images/I2.jpg");
    width:100%;
    height: 100%;
    position:absolute;
    top:0;
    left:0;
    z-index: -1;
}

.heading{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-img p{
    font-size: 1.4rem;
    text-align: center;
}
 
@media screen and (max-width:640px) {
    .hero-img h1{
        font-size: 2rem;
    }
}