@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: 'Outfit', sans-serif;
}

body{
  background-color:black;
}

h1,
h4,
p,
a{
  color:white;
  text-decoration: none;
}

ul{
  list-style-type: none;
}

.btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(10, 10, 10);
  color:rgb(247, 243, 243);
  border: 1px solid black;
  font-weight: 600;
  cursor: pointer;
}

.btn-light{
  background:white;
  color:rgb(15, 15, 15);
}

.btn:hover{
  background: transparent;
  color: rgb(241, 243, 243);
  transition: 0.3s;
}