.header{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:1rem;
    position:fixed;
    width:100%;
    height:90px;
    z-index: 10;
}
.header-bg{
    background-color: rgba(0,0,0,0.85);
    transition:0.5s;
}
.nav-menu{
    display: flex;
}
.nav-menu li{
    padding: 20px;
}

.nav-menu li a{
    font-size: 1.2rem;
    font-weight: 500;
}

.hamburger{
    display:initial;
}

@media screen and (max-width:1040px){
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:100%;
        height: 100vh;
        background:black;
        position:absolute;
        top: 0;
        left:-100%;
        /* left property equal to 100 makes hidden pf our content */
        z-index: -3;
        transition:0.3s;
    }
    .nav-menu.active{
        left:0;
    }

    .nav-menu li{
        padding: 1rem 0;
    }

    .nav-menu li a{
        font-size: 2rem;
    }
}